@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 300;
    src: local('OpenSans'), local('OpenSans-Regular'),
    url('./themes/fonts/OpenSans/OpenSans-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 500;
    src: local('OpenSans'), local('OpenSans-Medium'),
    url('./themes/fonts/OpenSans/OpenSans-Medium.ttf') format('ttf');
}

@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    src: local('OpenSans'), local('OpenSans-SemiBold'),
    url('./themes/fonts/OpenSans/OpenSans-SemiBold.ttf') format('ttf');
}

@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    src: local('OpenSans'), local('OpenSans-Bold'),
    url('./themes/fonts/OpenSans/OpenSans-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 800;
    src: local('OpenSans'), local('OpenSans-ExtraBold'),
    url('./themes/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('ttf');
}

html,
body {
    height: 100%;
    background-color: #f5f5f5 !important;
}

body {
    /* margin: 0; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
